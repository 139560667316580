import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';

import {
  EDefaultCardV2DescriptionSize,
  EDefaultCardV2ImagePosition,
  EDefaultCardV2ImageSize,
  EDefaultCardV2Paddings,
  EDefaultCardV2RightIconSize,
  EDefaultCardV2Size,
  EDefaultCardV2Theme,
  EDefaultCardV2TitleSize,
  EDefaultCardV2TopIconSize,
  THEMES,
} from './DefaultCardV2.constants';
import {
  Buttons,
  Description,
  descriptionCVA,
  Eyebrows,
  Title,
  TopIcon,
} from './components';
import { Button } from '../../atoms/Button';
import { EIconBottomPadding } from '../../atoms/Icon';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { ERichTextBullets, ERichTextBulletsTheme } from '../../atoms/RichText';

import type { ImageProps } from 'next/image';

const cardWrapperCVA = cva('min-w-[95%] basis-full px-2', {
  variants: {
    size: {
      [EDefaultCardV2Size.L]: 'md:min-w-[66.66%] md:basis-2/3',
      [EDefaultCardV2Size.M]: 'md:min-w-[50%] md:basis-1/2',
      [EDefaultCardV2Size.S]: 'md:min-w-[33.33%] md:basis-1/3',
      [EDefaultCardV2Size.Xs]:
        'md:min-w-[50%] md:basis-1/2 xl:min-w-[25%] xl:basis-1/4',
    },
  },
});

const cardContentCVA = cva('relative flex flex-1 flex-col p-6 lg:px-8', {
  variants: {
    paddings: {
      [EDefaultCardV2Paddings.TopAndBottom]: 'py-6 lg:py-8',
      [EDefaultCardV2Paddings.OnlyBottom]: 'pb-6 lg:pb-8',
      [EDefaultCardV2Paddings.OnlyTop]: 'pt-6 lg:pt-8',
    },
  },
});

const cardImageCva = cva('h-auto w-full object-contain object-bottom', {
  variants: {
    cardSize: {
      [EDefaultCardV2Size.L]: '',
      [EDefaultCardV2Size.M]: '',
      [EDefaultCardV2Size.S]: '',
      [EDefaultCardV2Size.Xs]: '',
    },
    imageSize: {
      [EDefaultCardV2ImageSize.Vertical]: '',
      [EDefaultCardV2ImageSize.Horizontal]: '',
    },
  },
  compoundVariants: [
    {
      cardSize: EDefaultCardV2Size.L,
      imageSize: [
        EDefaultCardV2ImageSize.Vertical,
        EDefaultCardV2ImageSize.Horizontal,
      ],
      className: 'md:h-[223px] lg:h-[271px] xl:h-[368px] 2xl:h-[460px]',
    },
    {
      cardSize: EDefaultCardV2Size.M,
      imageSize: EDefaultCardV2ImageSize.Vertical,
      className: 'md:h-[223px] lg:h-[271px] xl:h-[368px] 2xl:h-[460px]',
    },
    {
      cardSize: EDefaultCardV2Size.M,
      imageSize: EDefaultCardV2ImageSize.Horizontal,
      className: 'md:h-[165px] lg:h-[201px] xl:h-[272px] 2xl:h-[340px]',
    },
    {
      cardSize: EDefaultCardV2Size.S,
      imageSize: EDefaultCardV2ImageSize.Vertical,
      className: 'md:h-[223px] lg:h-[271px] xl:h-[368px] 2xl:h-[460px]',
    },
    {
      cardSize: EDefaultCardV2Size.S,
      imageSize: EDefaultCardV2ImageSize.Horizontal,
      className: 'md:h-[109.69px] lg:h-[132px] xl:h-[180px] 2xl:h-[225px]',
    },
    {
      cardSize: EDefaultCardV2Size.Xs,
      imageSize: [
        EDefaultCardV2ImageSize.Vertical,
        EDefaultCardV2ImageSize.Horizontal,
      ],
      className:
        'md:h-[338.5px] lg:h-[413.39px] xl:h-[271.4px] 2xl:h-[340.4px]',
    },
  ],
});

const dashedBorder =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23C8CAD9FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e";

type TBulletsOptions = {
  type?: ERichTextBullets;
  theme?: ERichTextBulletsTheme;
};

export type TDefaultCardV2CardProps = {
  image?: ImageProps | null;
  topIcon?: ImageProps | null;
  eyebrows?: string[];
  title: React.JSX.Element | string | null;
  description?: React.JSX.Element | string | null;
  rightIcon?: ImageProps | null;
  buttons?: React.ComponentProps<typeof Button>[];

  size?: EDefaultCardV2Size;
  imagePosition?: EDefaultCardV2ImagePosition;
  imageSize?: EDefaultCardV2ImageSize; // DEPRECATED, backward compatibility usage ONLY
  topIconSize?: EDefaultCardV2TopIconSize;
  rightIconSize?: EDefaultCardV2RightIconSize;
  titleSize?: EDefaultCardV2TitleSize;
  paddings?: EDefaultCardV2Paddings;
  theme?: EDefaultCardV2Theme;
  descriptionSize?: EDefaultCardV2DescriptionSize;
  className?: string;
  isRoundedIcon?: boolean;
  bulletsOptions?: TBulletsOptions;
};

export const DefaultCardV2: React.FC<TDefaultCardV2CardProps> = props => {
  const {
    image,
    topIcon,
    eyebrows,
    title,
    description,
    rightIcon,
    buttons,
    descriptionSize = EDefaultCardV2DescriptionSize.Medium,
    isRoundedIcon,
    size = EDefaultCardV2Size.L,
    imagePosition = EDefaultCardV2ImagePosition.Top,
    imageSize,
    topIconSize = EDefaultCardV2TopIconSize.Xl1,
    rightIconSize = EDefaultCardV2RightIconSize.L,
    titleSize = EDefaultCardV2TitleSize.L,
    paddings = EDefaultCardV2Paddings.TopAndBottom,
    theme = EDefaultCardV2Theme.Theme1,

    className,
  } = props;

  const themeStyles = THEMES[theme] || THEMES[EDefaultCardV2Theme.Theme1];
  const bulletsOptions = {
    ...props.bulletsOptions,
    theme: props.bulletsOptions?.theme || ERichTextBulletsTheme.Default,
  };

  return (
    <div
      className={cardWrapperCVA({ size, className })}
      style={
        theme === EDefaultCardV2Theme.Theme6
          ? ({
              '--custom-dashed-border': `url("${dashedBorder}")`,
            } as React.CSSProperties)
          : undefined
      }
    >
      <div
        className={clsx(
          'relative flex h-full flex-1 justify-between overflow-hidden rounded-3xl',
          themeStyles.borderStyle,
          themeStyles.cardBackground,
          image && imagePosition === EDefaultCardV2ImagePosition.Top
            ? 'flex-col-reverse'
            : 'flex-col',
        )}
      >
        {theme === EDefaultCardV2Theme.Theme6 && (
          <div className='absolute block size-full bg-[image:var(--custom-dashed-border)]' />
        )}

        {(!!eyebrows?.length || title || topIcon || description) && (
          <div className={cardContentCVA({ paddings })}>
            <div className='flex flex-1 flex-col'>
              <TopIcon
                topIcon={topIcon}
                topIconSize={topIconSize}
                isRoundedIcon={isRoundedIcon}
                bottomPadding={
                  eyebrows?.length || title || description
                    ? EIconBottomPadding.Yes
                    : EIconBottomPadding.No
                }
              />

              <Eyebrows
                eyebrows={eyebrows}
                className={themeStyles.eyebrowColor}
                wrapperClassName={title || description ? 'mb-4' : ''}
              />

              <Title
                title={title}
                titleSize={titleSize}
                rightIcon={rightIcon}
                rightIconSize={rightIconSize}
                className={clsx(themeStyles.titleColor)}
              />

              <Description
                description={description}
                descriptionSize={descriptionSize}
                className={descriptionCVA({
                  size,
                  className: clsx(themeStyles.descriptionColor, {
                    [themeStyles.bulletsColor]:
                      bulletsOptions.theme === ERichTextBulletsTheme.Default,
                  }),
                })}
              />
            </div>

            <Buttons
              buttons={buttons}
              buttonTheme={themeStyles.buttonTheme}
              className='mt-7'
            />
          </div>
        )}

        {image && (
          <ImageWithPlaceholder
            {...image}
            className={cardImageCva({ cardSize: size, imageSize })}
          />
        )}
      </div>
    </div>
  );
};
