import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';

import { EDefaultCardsV2Animation } from './DefaultCardsV2.constants';
import { useDragScrolling, useSequentialAnimations } from '../../../hooks';
import { Container } from '../../sections/Container';
import { DefaultCardV2 } from '../DefaultCardV2';
import { EDefaultCardsHorizontalScroll } from '../DefaultCards';

const wrapperCva = cva('scrollbar-hidden flex', {
  variants: {
    horizontalScroll: {
      [EDefaultCardsHorizontalScroll.Disabled]: '',
      [EDefaultCardsHorizontalScroll.Enabled]: 'overflow-auto scroll-smooth',
      [EDefaultCardsHorizontalScroll.Mobile]:
        'overflow-auto scroll-smooth xl:overflow-hidden',
      [EDefaultCardsHorizontalScroll.Desktop]:
        'xl:overflow-auto xl:scroll-smooth',
    },
  },
});

const cardCva = cva('-mx-2 flex items-stretch gap-y-4', {
  variants: {
    horizontalScroll: {
      [EDefaultCardsHorizontalScroll.Disabled]: 'flex-wrap',
      [EDefaultCardsHorizontalScroll.Enabled]: 'flex-nowrap after:min-w-2',
      [EDefaultCardsHorizontalScroll.Mobile]:
        'flex-nowrap max-xl:after:min-w-2 xl:flex-wrap',
      [EDefaultCardsHorizontalScroll.Desktop]:
        'flex-wrap xl:flex-nowrap xl:after:min-w-2',
    },
  },
});

type TDefaultCardsV2Props = {
  banners: React.ComponentProps<typeof DefaultCardV2>[];
  horizontalScroll?: EDefaultCardsHorizontalScroll;
  isSectionAnimationReady?: boolean;
  animation?: `${EDefaultCardsV2Animation}`;
};

export const DefaultCardsV2: React.FC<TDefaultCardsV2Props> = props => {
  const { banners, horizontalScroll, isSectionAnimationReady, animation } =
    props;

  const { scrollableRef, bind } = useDragScrolling({
    enabled: horizontalScroll !== EDefaultCardsHorizontalScroll.Disabled,
  });

  const isAnimationEnabled = animation === EDefaultCardsV2Animation.Enabled;

  const { ref, transitionClasses } = useSequentialAnimations({
    count: banners.length,
    delay: 200,
    startClasses: 'opacity-0 translate-y-8',
    finishClasses: 'opacity-100',
    enabled: isAnimationEnabled && isSectionAnimationReady,
    threshold: 0.4,
  });

  return (
    <section
      ref={scrollableRef}
      className={wrapperCva({ horizontalScroll })}
      {...bind()}
    >
      <Container>
        <div ref={ref} className={cardCva({ horizontalScroll })}>
          {banners.map((banner, i) => (
            <DefaultCardV2
              key={i}
              {...banner}
              className={clsx(
                isAnimationEnabled && 'transition-all duration-300 ease-in',
                isAnimationEnabled && transitionClasses[i],
              )}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
